import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/base/standard-page.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';

const Contactless = () => {
  const data = useStaticQuery(graphql`
    query {
      dropbox: file(relativePath: { eq: "contactless/contactless-dropbox-graphic.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobile: file(relativePath: { eq: "contactless/contactless-mobile-graphic.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hygiene: file(relativePath: { eq: "contactless/contactless-deathygiene-graphic.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactlessImage: file(relativePath: { eq: "contactless/contactless-graphic.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactless {
        seo {
          title
          canonical
          description
          og {
            description
            image
            title
            type
            url
          }
        }
      }
    }
  `);

  const { seo } = data.contactless;
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <div className="hero-banner">
        <div className="container">
          <h6>Contactless Repair</h6>
          <h1>DingGo's Commitment to Safety</h1>
        </div>
      </div>
      <div className="main-wrapper">
        <div className="py-7">
          <div className="container">
            <div className="row flex-row-reverse justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.dropbox.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>What is a contactless repair?</h2>
                <p>A contactless repair is a new option where you can have your car repaired with no direct contact with your repairer.</p>
                <p>
                  For workshops this involves either a safe key drop off at the workshop when you drop off/pick up your car or if the repairer agrees they will pick up and drop off your car for you and you just have to organise to leave the car and keys somewhere safe for them to collect.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="py-7 bg-light">
          <div className="container">
            <div className="row mb-7 justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.mobile.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>Can a contactless repair be done mobile?</h2>

                <p>Yes absolutely. Mobile repairers will happily come to you, and will be able to fix your car without touching the interior of the car. If accessing the interior is required they will sanitise all surfaces before and after the repair.
                </p>
                <p>
                  For mobile repairs you just have to organise to leave the car and keys out for them in a safe place where they have access to power and a secure area to access and repair your car.</p>
              </div>
            </div>
            <div className="row mb-7 flex-row-reverse justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.hygiene.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>Hygiene and safety is the priority</h2>

                <p>All our panel beaters are implementing government recommendations regarding social distancing, hand washing, and regular cleaning of the workshops. In addition they have taken further steps to protect themselves, their employees, and customers by disinfecting every car before it comes into their shop and before the car is returned to you.</p>
              </div>
            </div>
            <div className="row mb-7 justify-content-around align-items-center">
              <div className="col-4">
                <Img fluid={data.contactlessImage.childImageSharp.fluid} />
              </div>
              <div className="col-6">
                <h2>Help local businesses survive</h2>

                <p>Please note all our panel beaters are currently open for business and both need and appreciate your business in these tricky times. We know there are car repairs out there to be done and we are doing our best to ensure these repairs are done as safely as possible. It's going to be a tough few months ahead, but as an industry and community we can support each other to get through it together.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Contactless;
